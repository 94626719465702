<template>
  <v-navigation-drawer>

    <v-list-item
      v-if="isAuthenticated"
      lines="two"
      Xprepend-avatar="https://randomuser.me/api/portraits/women/81.jpg"
      prepend-avatar="/images/pika.png"
    >
      <template v-slot:title="">
        <span style="font-size: 10px">{{ email }}</span>
      </template>
      <template v-slot:prepend-avatar="">
        <img src="/images/pika.png" />
        asd
      </template>
      <template v-slot:subtitle="">
        <v-btn v-if="isAuthenticated" @click="logout" size="small">quit</v-btn>
      </template>
    </v-list-item>

    <v-list
      density="compact"
      nav
      v-if="isAuthenticated"
      base-color="#1eb1eb"
    >
      <v-list-item to="/" prepend-icon="mdi-folder" title="Gatherpal"></v-list-item>
      <v-list-item v-if="isAdmin" to="collections" prepend-icon="mdi-cards-outline" title="Collections"></v-list-item>
    </v-list>

    <v-menu
      v-if="!isAuthenticated"
    >
      <template v-slot:activator="{ props }">
        <v-list
          v-bind="props"
        >
          <v-list-item v-if="!isAuthenticated && isDevelopment" to="login" prepend-icon="mdi-security" title="Login"></v-list-item>
          <v-list-item v-if="!isAuthenticated && isDevelopment" to="signup" prepend-icon="mdi-security" title="Signup"></v-list-item>
        </v-list>
      </template>
    </v-menu>

    <v-divider></v-divider>

    <v-list
      density="compact"
      nav
      v-if="isAdmin"
      base-color="orange"
    >
      <v-list-item to="/admin" prepend-icon="mdi-security" title="Admin"></v-list-item>
      <v-list-item :to="{name: 'admin-collection-list'}" prepend-icon="mdi-cards" title="Collections"></v-list-item>
      <v-list-item :to="{name: 'admin-crawler-list'}" prepend-icon="mdi-cloud-print" title="Crawlers"></v-list-item>
      <v-list-item :to="{name: 'admin-mapper-list'}" prepend-icon="mdi-database-refresh" title="Mappers"></v-list-item>
      <v-list-item :to="{name: 'admin-changeset-list'}" prepend-icon="mdi-database-refresh" title="Changesets"></v-list-item>
      <v-list-item :to="{name: 'admin-bulk-action-list'}" prepend-icon="mdi-animation-play" title="Bulk Actions"></v-list-item>
      <v-list-item :to="{name: 'admin-dynamic-image-list'}" prepend-icon="mdi-image-area" title="Dynamic Images"></v-list-item>
      <v-list-item :to="{name: 'admin-export-list'}" prepend-icon="mdi-database-export" title="Exports"></v-list-item>
      <v-list-item :to="{name: 'admin-user-list'}" prepend-icon="mdi-account-group" title="Users"></v-list-item>
      <v-list-item :to="{name: 'admin-scripts'}" prepend-icon="mdi-console" title="Scripts"></v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<script>

import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '../store/auth'
import { useConfigStore } from '../store/config'

export default {
  computed: {
    ...mapState(useAuthStore, ['isAuthenticated', 'isAdmin', 'email']),
    ...mapState(useConfigStore, ['isDevelopment']),
  },
  methods: {
    ...mapActions(useAuthStore, ['removeAccessToken']),
    async logout() {
      await this.removeAccessToken();
      await this.$router.push('/')
      window.document.location.reload();
    },
  },
}
</script>
