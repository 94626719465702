// stores/useDialogStore.ts
import { defineStore } from 'pinia'

// interface Dialog {
//   visible: boolean
//   title: string
//   text: string
//   callback: Function | null
//   textSlot: any | null
// }

const defaultDialog = {
  visible: false,
  title: '',
  text: '',
  callback: null,
  textSlot: null,
}

export const dialog = defineStore('dialog', {
  state: () => ({
    dialog: { ...defaultDialog },
  }),
  actions: {
    openDialog({
                 title,
                 text,
                 callback,
                 textSlot,
               }) {
      this.dialog = {
        ...this.dialog,
        visible: true,
        title: title ?? '',
        text: text ?? '',
        callback: callback ?? null,
        textSlot: textSlot ?? null,
      }
    },
    closeDialog() {
      this.dialog = { ...defaultDialog }
    },
  },
})
