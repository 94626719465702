<template>
  <v-card
    class="mx-auto my-8 debug-dialog"
    elevation="16"
    max-width="344"
  >
    <v-card-item>
      <v-card-subtitle @click="switchVisible" style="cursor: pointer">Debug</v-card-subtitle>
    </v-card-item>

    <v-card-text v-if="visible">
      <pre style="margin: 0; padding: 0">
--- config ---
isProduction: {{ JSON.stringify(isProduction) }}
apiUrl: {{ JSON.stringify(apiUrl) }}
--- auth ---
isAuthenticated: {{ JSON.stringify(isAuthenticated) }}
isAdmin: {{ JSON.stringify(isAdmin) }}
userId: {{ JSON.stringify(userId) }}
email: {{ JSON.stringify(email) }}
loading: {{ JSON.stringify(loading) }}
      </pre>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia';
import { useConfigStore } from '../store/config';
import { useAuthStore } from '../store/auth';

const stored = localStorage.getItem('debugVisible');

export default {
  data() {
    return {
      visible: stored ? stored === 'true' : true,
    };
  },
  computed: {
    ...mapState(useConfigStore, ['apiUrl', 'isProduction']),
    ...mapState(useAuthStore, ['isAuthenticated', 'isAdmin', 'userId', 'email', 'loading']),
  },
  methods: {
    switchVisible() {
      this.visible = !this.visible;
      localStorage.setItem('debugVisible', this.visible ? 'true' : 'false');
    },
  },
}
</script>

<style scoped>
.debug-dialog {
  position: fixed;
  bottom: 0px;
  right: 16px;
  z-index: 1000;
}
</style>
