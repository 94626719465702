<!-- src/components/GlobalDialog.vue -->
<template>
  <v-dialog v-model="visible" max-width="300">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        {{ text }}
        <component :is="textSlot" />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="confirm">Confirm</v-btn>
        <v-btn @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { dialog } from '../../store/dialog'

export default defineComponent({
  name: 'GlobalDialog',
  setup() {
    const dialogStore = dialog()

    // Computed references to dialog state
    const visible = computed(() => dialogStore.dialog.visible)
    const title = computed(() => dialogStore.dialog.title)
    const text = computed(() => dialogStore.dialog.text)
    const callback = computed(() => dialogStore.dialog.callback)
    const textSlot = computed(() => dialogStore.dialog.textSlot)

    // Methods
    const confirm = () => {
      if (callback.value) {
        callback.value()
      }
      dialogStore.closeDialog()
    }

    const cancel = () => {
      dialogStore.closeDialog()
    }

    return {
      visible,
      title,
      text,
      textSlot,
      confirm,
      cancel,
    }
  },

})
</script>
