// stores/useEnvStore.ts
import { defineStore } from 'pinia'
import { default as configJs } from '../config';

// const isProduction = process.env.NODE_ENV === 'production'
// const isDevelopment = !isProduction

export const useConfigStore = defineStore('config', {
  state: () => ({
    ...configJs
  }),
  actions: {
    // toggle() {
    //   this.isProduction = !this.isProduction
    //   this.isDevelopment = !this.isDevelopment
    // },
  }
});
