import axios from 'axios';
import { useAuthStore } from './store/auth';
import config from "@/config";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const authStore = useAuthStore();
    const token = authStore.getAccessToken();
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
