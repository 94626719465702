
const isProduction = process.env.NODE_ENV === 'production';

const config = {
  aws: {
    publicBucket: 'gatherpal-public',
  },
  isProduction,
  isDevelopment: !isProduction,
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  apiUrl: isProduction ? 'https://gatherpal.org/api' : `http://localhost:8080/api`,
};

export default config;
