import { defineStore } from 'pinia'
import { reactive, toRefs, watch } from 'vue'
import UserDataService from "@/services/UserDataService";

const getAccessToken = () => {
  return localStorage.getItem('token') || null;
}

const setAccessToken = token => {
  return localStorage.setItem('token', token);
}

const removeAccessToken = () => {
  return localStorage.removeItem('token');
}

export const useAuthStore = defineStore('auth', () => {

  const state = reactive({
    isAuthenticated: false,
    isAdmin: false,
    userId: null,
    email: null,
    loading: true,
  });

  async function initialize() {
    if(!getAccessToken()) {
      state.loading = false;
      return
    }
    const info = await UserDataService.info();
    console.log("info", typeof info, info);

    state.loading = false;
    state.userId = info.userId;
    state.isAuthenticated = !!state.userId;
    state.isAdmin = info.isAdmin;
    state.email = info.email;
  }

  return {
    ...toRefs(state),
    getAccessToken,
    setAccessToken,
    removeAccessToken,
    initialize,
  }
})
